var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "flexAround" }, [
        _c("div", { staticClass: "overviewBox background1" }, [
          _vm._m(0),
          _c("div", { staticClass: "rightShow" }, [
            _c(
              "div",
              { staticClass: "top" },
              [
                _vm._v(" 车场总数 "),
                _c(
                  "el-tooltip",
                  { attrs: { slot: "left", placement: "top" }, slot: "left" },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(" 车场总数：“商用+试运行状态”下的停车场总数"),
                      _c("br"),
                      _vm._v("泊位总数：“商用+试运行状态”下的停车场泊位总数 "),
                    ]),
                    _c("i", { staticClass: "el-icon-question" }),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "center" }, [
              _vm._v(" " + _vm._s(_vm.parkCount) + " "),
              _c("span", { staticClass: "unit" }, [_vm._v("个")]),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _vm._v(" 泊位总数"),
              _c("span", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.berthCount) + "个"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "overviewBox background2" }, [
          _vm._m(1),
          _c("div", { staticClass: "rightShow" }, [
            _c(
              "div",
              { staticClass: "top" },
              [
                _vm._v(" 今日停车记录数 "),
                _c(
                  "el-tooltip",
                  { attrs: { slot: "left", placement: "top" }, slot: "left" },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " 今日停车记录数：入场时间从今日0:00至当前时间的停车记录数量"
                      ),
                      _c("br"),
                      _vm._v(
                        "日均停车记录：先算出车场每一天的入场停车记录总数，再用近7天的每天停车记录之和÷7 "
                      ),
                    ]),
                    _c("i", { staticClass: "el-icon-question" }),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "center" }, [
              _vm._v(" " + _vm._s(_vm.exitCount) + " "),
              _c("span", { staticClass: "unit" }, [_vm._v("个")]),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _vm._v(" 日均停车记录数"),
              _c("span", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.averageExitCount) + "个"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "overviewBox background3" }, [
          _vm._m(2),
          _c("div", { staticClass: "rightShow" }, [
            _c(
              "div",
              { staticClass: "top" },
              [
                _vm._v(" 今日收入总额 "),
                _c(
                  "el-tooltip",
                  { attrs: { slot: "left", placement: "top" }, slot: "left" },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " 今日收入总额：收款时间为今日0:00至当前时间的总额"
                      ),
                      _c("br"),
                      _vm._v(
                        "日均收入总额：先算出车场每一天收入总额，再用近7天的每天收入总额之和÷7 "
                      ),
                    ]),
                    _c("i", { staticClass: "el-icon-question" }),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "center" }, [
              _vm._v(" " + _vm._s(_vm.actualPay / 100) + " "),
              _c("span", { staticClass: "unit" }, [_vm._v("元")]),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _vm._v(" 日均收入总额"),
              _c("span", { staticClass: "num" }, [
                _vm._v(
                  _vm._s(_vm._f("moneyHandling")(_vm.averageActualPay)) + "元"
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "overviewBox background4" }, [
          _vm._m(3),
          _c("div", { staticClass: "rightShow" }, [
            _c(
              "div",
              { staticClass: "top" },
              [
                _vm._v(" 日均时长泊位利用率 "),
                _c(
                  "el-tooltip",
                  { attrs: { slot: "left", placement: "top" }, slot: "left" },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " 日均泊位时长利用率：近7天内，每天泊位时长利用率之和÷7。每天的泊位时长利用率=当天出场车辆的停车时长之和÷当天的泊位数÷24。"
                      ),
                      _c("br"),
                      _vm._v(
                        "日均泊位周转次数：近7天内，每天泊位周转次数之和÷7。每天的泊位周转次数=当天出场车辆的停车记录之和÷当天的泊位数。 "
                      ),
                    ]),
                    _c("i", { staticClass: "el-icon-question" }),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "center" }, [
              _vm._v(" " + _vm._s(_vm.parkTimeRatio) + " "),
              _c("span", { staticClass: "unit" }, [_vm._v("%")]),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _vm._v(" 日均泊位周转次数"),
              _c("span", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.averageBerthTurnover) + "次"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "colLeft", attrs: { span: 16 } }, [
                _c(
                  "div",
                  { staticClass: "divSize" },
                  [
                    _c("div", { staticClass: "divIcon" }),
                    _c(
                      "h2",
                      [
                        _vm._v(" 停车收入概览 "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              slot: "left",
                              content:
                                "停车收入概览：按支付时间统计实收，指每个区域，支付时间在最近7天，所有车辆实收数据。",
                              placement: "top",
                            },
                            slot: "left",
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question",
                              staticStyle: { color: "#d0d0d0" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("parkingRevenue"),
                  ],
                  1
                ),
              ]),
              _c("el-col", { staticClass: "colRight", attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "divSize" },
                  [
                    _c("div", { staticClass: "divIcon" }),
                    _c(
                      "h2",
                      { staticClass: "top5" },
                      [
                        _vm._v(" 停车收入top5 "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              slot: "left",
                              content:
                                "停车收入top5：统计每个停车场最近7天支付的实收金额，取前5展示",
                              placement: "top",
                            },
                            slot: "left",
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question",
                              staticStyle: { color: "#d0d0d0" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.parkRevenueTopList, function (park, index) {
                      return _c(
                        "el-row",
                        { key: index, staticClass: "row" },
                        [
                          _c("el-col", { staticClass: "col" }, [
                            _c(
                              "span",
                              {
                                staticClass: "indexNumber",
                                class: [
                                  index + 1 > 3 ? "bottomNumber" : "topNumber",
                                ],
                              },
                              [_vm._v(_vm._s(index + 1))]
                            ),
                            _c("span", { staticClass: "item" }, [
                              _vm._v(_vm._s(park.parkname)),
                            ]),
                            _c("span", { staticClass: "itemMoney" }, [
                              _vm._v(_vm._s((park.actualpay / 100).toFixed(2))),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "colLeft", attrs: { span: 16 } }, [
                _c(
                  "div",
                  { staticClass: "divSize" },
                  [
                    _c("div", { staticClass: "divIcon" }),
                    _c(
                      "h2",
                      [
                        _vm._v(" 停车记录概览 "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              slot: "left",
                              content:
                                "停车记录概览：按入场时间统计每个地区最近7天停车记录数。",
                              placement: "top",
                            },
                            slot: "left",
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question",
                              staticStyle: { color: "#d0d0d0" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("parkingRecord"),
                  ],
                  1
                ),
              ]),
              _c("el-col", { staticClass: "colRight", attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "divSize" },
                  [
                    _c("div", { staticClass: "divIcon" }),
                    _c(
                      "h2",
                      { staticClass: "top5" },
                      [
                        _vm._v(" 停车记录top5 "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              slot: "left",
                              content:
                                "停车记录top5：按入场时间统计每个车场最近7天停车记录，取前5展示",
                              placement: "top",
                            },
                            slot: "left",
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question",
                              staticStyle: { color: "#d0d0d0" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.parkRecordList, function (park, index) {
                      return _c(
                        "el-row",
                        { key: index, staticClass: "row" },
                        [
                          _c("el-col", { staticClass: "col" }, [
                            _c(
                              "span",
                              {
                                staticClass: "indexNumber",
                                class: [
                                  index + 1 > 3 ? "bottomNumber" : "topNumber",
                                ],
                              },
                              [_vm._v(_vm._s(index + 1))]
                            ),
                            _c("span", { staticClass: "item" }, [
                              _vm._v(_vm._s(park.parkname)),
                            ]),
                            _c("span", { staticClass: "itemMoney" }, [
                              _vm._v(_vm._s(park.parkingCnt)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "leftShow" }, [
      _c("img", {
        attrs: { src: require("../../assets/img/park.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "leftShow" }, [
      _c("img", {
        attrs: { src: require("../../assets/img/park1.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "leftShow" }, [
      _c("img", {
        attrs: { src: require("../../assets/img/money.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "leftShow" }, [
      _c("img", {
        attrs: { src: require("../../assets/img/berth.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }